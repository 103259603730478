/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotografie"}>
        <SiteHeader />

        <Column className="css-d20cu2 --style2 --full --parallax" anim={""} name={"uvod"} animS={"3"} border={""} parallax={true} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/4546/762a1efa69b046509d0dfbdce0b4f782_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/4546/762a1efa69b046509d0dfbdce0b4f782_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/4546/762a1efa69b046509d0dfbdce0b4f782_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/4546/762a1efa69b046509d0dfbdce0b4f782_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/4546/762a1efa69b046509d0dfbdce0b4f782_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/4546/762a1efa69b046509d0dfbdce0b4f782_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/4546/762a1efa69b046509d0dfbdce0b4f782_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/4546/762a1efa69b046509d0dfbdce0b4f782_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="pb--60 pt--60" name={"ixkr1nvn0ul"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Ahoj, jsem Stella"}>
              </Title>

              <Image className="--shape5" src={"https://cdn.swbpg.com/t/4546/48fa0f9657124fca85ae8ef6c6635728_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/4546/48fa0f9657124fca85ae8ef6c6635728_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/48fa0f9657124fca85ae8ef6c6635728_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/48fa0f9657124fca85ae8ef6c6635728_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingBottom":0,"backgroundColor":"rgba(199,167,149,0.43)"}}>
              
              <Text className="text-box text-box--left" style={{"marginBottom":0,"paddingBottom":2}} content={"<span style=\"color: var(--color-custom-2);\"><span style=\"font-weight: bold;\">JSEM FOTOGRAFKA A KOORDINÁTORKA</span><br><br>...Vašich životních příběhů.<br><br>Při focení se nechávám unášet svou fantazií.<br>Portrétování lidí je tvorba, která musí odrážet nejen mou, ale i energii mých klientů.\n<br>Vedu své klienty při focení k náladě, která je jim blízká. <br>Vedu je k objevení krásy své vlastní fotografie.<br><br>Čas, který NIKDY nezastavíme, můžeme zachytit ve fotografii.<br>Tvořím s Vámi fotografie, které mají svou hodnotu.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fwbxtzkii0o"} style={{"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Ceník a obchodní podmínky"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":1488,"paddingLeft":0}} content={"Rezervací termínu stvrzujete přečtení a respektování <a href=\"/obchodnipodminky\"><span style=\"font-weight: bold;\">ceníku a</span>&nbsp;<span style=\"font-weight: bold;\">obchodních podmínek</span>.</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"ipsx0yoqjal"} style={{"marginTop":0,"paddingTop":99,"paddingBottom":13}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Vánoční školkové minifocení 2023"}>
              </Title>

              <Text className="text-box text-box--center" content={"Lesní školička"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"w357je40e3d"} style={{"paddingTop":0,"paddingBottom":58}} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"maxWidth":2228}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/61ce8896d95f458e8cbb5dd543b7f3d8_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/4546/61ce8896d95f458e8cbb5dd543b7f3d8_s=350x_.JPG 350w, https://cdn.swbpg.com/t/4546/61ce8896d95f458e8cbb5dd543b7f3d8_s=660x_.JPG 660w, https://cdn.swbpg.com/t/4546/61ce8896d95f458e8cbb5dd543b7f3d8_s=860x_.JPG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/48b171625df747fca51b936ea24c3da2_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/4546/48b171625df747fca51b936ea24c3da2_s=350x_.JPG 350w, https://cdn.swbpg.com/t/4546/48b171625df747fca51b936ea24c3da2_s=660x_.JPG 660w, https://cdn.swbpg.com/t/4546/48b171625df747fca51b936ea24c3da2_s=860x_.JPG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/8dbd87b9566741d497838c0920a4f345_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/4546/8dbd87b9566741d497838c0920a4f345_s=350x_.JPG 350w, https://cdn.swbpg.com/t/4546/8dbd87b9566741d497838c0920a4f345_s=660x_.JPG 660w, https://cdn.swbpg.com/t/4546/8dbd87b9566741d497838c0920a4f345_s=860x_.JPG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/83ab8a890fa549a087a0af86472984b8_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/4546/83ab8a890fa549a087a0af86472984b8_s=350x_.JPG 350w, https://cdn.swbpg.com/t/4546/83ab8a890fa549a087a0af86472984b8_s=660x_.JPG 660w, https://cdn.swbpg.com/t/4546/83ab8a890fa549a087a0af86472984b8_s=860x_.JPG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"w357je40e3d"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/b68d45e3e4eb482fa157c155174fa1ca_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/4546/b68d45e3e4eb482fa157c155174fa1ca_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/b68d45e3e4eb482fa157c155174fa1ca_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/b68d45e3e4eb482fa157c155174fa1ca_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/41d193d987564b2983af279953837af4_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/4546/41d193d987564b2983af279953837af4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/41d193d987564b2983af279953837af4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/41d193d987564b2983af279953837af4_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/eaf688d564c24586aace184ef3e61f9a_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/4546/eaf688d564c24586aace184ef3e61f9a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/eaf688d564c24586aace184ef3e61f9a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/eaf688d564c24586aace184ef3e61f9a_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/edde85380ed54debae9af3251d9e75d2_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/4546/edde85380ed54debae9af3251d9e75d2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/edde85380ed54debae9af3251d9e75d2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/edde85380ed54debae9af3251d9e75d2_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/8bab63966f6c4ac98c945e0f01cb1d5e_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/4546/8bab63966f6c4ac98c945e0f01cb1d5e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/8bab63966f6c4ac98c945e0f01cb1d5e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/8bab63966f6c4ac98c945e0f01cb1d5e_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/8ef3ccbf0d054a62a6cbe70dcc957ec4_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/4546/8ef3ccbf0d054a62a6cbe70dcc957ec4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/8ef3ccbf0d054a62a6cbe70dcc957ec4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/8ef3ccbf0d054a62a6cbe70dcc957ec4_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"babmjdwvn9r"} style={{"paddingTop":10}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--stretch" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/2efd3c069bd9410c8e0876e36067873d_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/4546/2efd3c069bd9410c8e0876e36067873d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/2efd3c069bd9410c8e0876e36067873d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/2efd3c069bd9410c8e0876e36067873d_s=860x_.jpg 860w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/4546/fa0f1aa0a3c34978a5ee707b28ebcfe5_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/4546/fa0f1aa0a3c34978a5ee707b28ebcfe5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/fa0f1aa0a3c34978a5ee707b28ebcfe5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/fa0f1aa0a3c34978a5ee707b28ebcfe5_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/4546/8bee53243a88492c8aa4c8b10a9386c0_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/4546/8bee53243a88492c8aa4c8b10a9386c0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/8bee53243a88492c8aa4c8b10a9386c0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/8bee53243a88492c8aa4c8b10a9386c0_s=860x_.jpg 860w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/4546/f33327896d3c4c5f9cacd5a5d22c9c5a_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/4546/f33327896d3c4c5f9cacd5a5d22c9c5a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/4546/f33327896d3c4c5f9cacd5a5d22c9c5a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/4546/f33327896d3c4c5f9cacd5a5d22c9c5a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/4546/f33327896d3c4c5f9cacd5a5d22c9c5a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/4546/f33327896d3c4c5f9cacd5a5d22c9c5a_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20" name={"patka"} style={{"paddingTop":35,"backgroundColor":"rgba(224, 224, 224, 1)"}} layout={"l1"} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Ateliér Tůtojc Stod"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":394}} content={"Stella Jarošová<br>IČO:&nbsp;08663696<br>Sídlo:\nMířovice 10,\n333 01 Ves Touškov<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":398}} srcSet={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}